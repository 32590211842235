import { AgentRank } from "@/core/model/AgentRank";
import { get } from "@/core/service/RankingService";
import { reactive } from "vue";

const state = reactive<{ services?: AgentRank[]}>({
  services: undefined,
});

const actions = {
  async GetAll(range?: string[]) {
    const data = await get(range);

    state.services = data;

    return state.services;
  },
};

export { actions }