import { AnalysisRating } from "@/core/model/AnalysisRating";
import { get, getByActivity, getByCategory, getByDepartment } from "@/core/service/RatingService";
import { reactive } from "vue";

const state = reactive<{ services?: AnalysisRating[]}>({
  services: undefined,
});

const actions = {
  async GetAll(range?: string[]) {
    const data = await get(range);

    state.services = data;

    return state.services;
  },
  
  async GetByDepartment(id: string, range?: string[]) {
    const data = await getByDepartment(id, range);

    return data;
  },

  async GetByCategory(id: string, range?: string[]) {
    const data = await getByCategory(id, range);

    return data;
  },

  async GetByActivity(id: string, range?: string[]) {
    const data = await getByActivity(id, range);
    return data;
  }
};

export { actions }