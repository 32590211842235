import { refreshAllData } from '@/store';
import { http } from '../api';
import { OptionSelect, PaginateResponse } from '../model';
import { CreateDepartmentRequest, Department } from '../model/Department';

const controller = 'department';

export type DepartmentSearch = {
  page: number,
  limit: number,
  showDisabled?: boolean,
}

type ResponseDepartment = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Department[];
}

const mountParams = (search: DepartmentSearch) => {
  const queryParams = Object.entries(search).map(([key, value]) => `${key}=${value}`).join('&');
  return `?${queryParams}`;
}

export async function getPaginate(search: DepartmentSearch = { page: 1, limit: 10000, showDisabled: true }): Promise<PaginateResponse<Department>> {
  const { data } = await http.get<ResponseDepartment>(`${controller}/${mountParams(search)}`);
  return { page: data.page, recordsTotal: data.total, data: data.data };
}

export async function getBy(id: string): Promise<Department> {
  const { data } = await http.get<Department>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.post<Department>(`${controller}/`, payload);
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.patch<Department>(`${controller}/${id}`, payload);
  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

export async function enable(id: string): Promise<Department> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  await refreshAllData();
  return data;
}

export async function disable(id: string): Promise<Department> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  await refreshAllData();
  return data;
}

export async function getSelectIconListDepartament(): Promise<OptionSelect[]> {
  const data = [
    { text: 'Dashboard', value: 'mdi-view-dashboard' },
    { text: 'Cama de Hospital', value: 'mdi-bed' },
    { text: 'Prontuário Médico', value: 'mdi-clipboard-pulse' },
    { text: 'Administração', value: 'mdi-office-building' },
    { text: 'Recursos Humanos', value: 'mdi-human-handsup' },
    { text: 'Finanças', value: 'mdi-currency-usd' },
    { text: 'Paciente', value: 'mdi-account-heart' },
    { text: 'Agendamento', value: 'mdi-calendar-clock' },
    { text: 'Laboratório', value: 'mdi-flask' },
    { text: 'Cirurgia', value: 'mdi-human-baby-changing-table' },
    { text: 'Ambulância', value: 'mdi-ambulance' },
    { text: 'Emergência', value: 'mdi-alert' },
    { text: 'Médico', value: 'mdi-stethoscope' },
    { text: 'Hospital', value: 'mdi-hospital-building' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Psicologia', value: 'mdi-head-snowflake' },
    { text: 'Enfermeiro', value: 'mdi-doctor' },
    { text: 'Negócios', value: 'mdi-handshake' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Coração', value: 'mdi-heart-pulse' },
    { text: 'Saúde & Bem Estar', value: 'mdi-heart-plus' },
    { text: 'Cuidados com o Paciente', value: 'mdi-mother-heart' },
    { text: 'Diagnóstico', value: 'mdi-puzzle-heart' },
    { text: 'Ressonância', value: 'mdi-radioactive' },
    { text: 'Pediatria', value: 'mdi-car-child-seat' },
    { text: 'Transporte de Paciente', value: 'mdi-transit-transfer' },
    { text: 'Acessibilidade', value: 'mdi-wheelchair-accessibility' },
    { text: 'Medicação', value: 'mdi-medical-bag' },
    { text: 'Paciente Aguardando', value: 'mdi-account-clock' },
    { text: 'Ajuda', value: 'mdi-hand-heart' },
    { text: 'Higiene', value: 'mdi-lotion-plus' },
    { text: 'Conserto', value: 'mdi-hammer-wrench' },
    { text: 'Curativo', value: 'mdi-bandage' },
    { text: 'Edifício', value: 'mdi-office-building-marker' },
    { text: 'Manutenção', value: 'mdi-wrench' },
    { text: 'Dentista', value: 'mdi-tooth' },
    { text: 'Pulmão', value: 'mdi-lungs' },
    { text: 'Alimentação', value: 'mdi-silverware-fork-knife' },
    { text: 'Localização', value: 'mdi-map-marker-plus' },
    { text: 'Casa', value: 'mdi-home-plus' },
    { text: 'Idoso', value: 'mdi-human-cane' },
    { text: 'Localização Hospitalar', value: 'mdi-hospital-marker' },
    { text: 'Medicação Intravenosa', value: 'mdi-iv-bag' },
    { text: 'Perigo', value: 'mdi-skull-scan-outline' },
    { text: 'Vírus', value: 'mdi-virus-outline' },
    { text: 'Exame', value: 'mdi-diabetes' },
    { text: 'Genética', value: 'mdi-dna' },
    { text: 'Máscara', value: 'mdi-face-mask-outline' },
    { text: 'Microscópio', value: 'mdi-microscope' },
    { text: 'Pessoa', value: 'mdi-walk' },
    { text: 'Receita', value: 'mdi-file-document-edit' },
    { text: 'Documento', value: 'mdi-file-document-multiple-outline' },
    { text: 'Telefone', value: 'mdi-phone-dial' },
    { text: 'Cofre', value: 'mdi-piggy-bank-outline' },
    { text: 'Cama Dupla', value: 'mdi-bed-double-outline' },
    { text: 'Cama King', value: 'mdi-bed-king-outline' },
    { text: 'Cama com Alarme', value: 'mdi-bed-clock' },
    { text: 'Pá', value: 'mdi-shovel' },
    { text: 'Tulipa', value: 'mdi-flower-tulip' },
    { text: 'Spa', value: 'mdi-spa-outline' },
    { text: 'Meditação', value: 'mdi-meditation' },
    { text: 'Basquete', value: 'mdi-basketball' },
    { text: 'Baseball', value: 'mdi-baseball-bat' },
    { text: 'Corrida', value: 'mdi-run-fast' },
    { text: 'Halterofilismo', value: 'mdi-weight-lifter' },
    { text: 'Ginástica', value: 'mdi-gymnastics' },
    { text: 'Arco e Flecha', value: 'mdi-bow-arrow' },
    { text: 'Halter', value: 'mdi-dumbbell' },
    { text: 'Comida', value: 'mdi-food-outline' },
    { text: 'Golfe', value: 'mdi-golf-tee' },
    { text: 'Chapéu de Chef', value: 'mdi-chef-hat' },
    { text: 'Sorvete', value: 'mdi-ice-cream' },
    { text: 'Maçã', value: 'mdi-food-apple-outline' },
    { text: 'Frango', value: 'mdi-food-turkey' },
    { text: 'Cafeteira', value: 'mdi-coffee-maker' },
    { text: 'Massa', value: 'mdi-pasta' },
    { text: 'Baguete', value: 'mdi-baguette' },
    { text: 'Bolo', value: 'mdi-cake-variant-outline' },
    { text: 'Vinho', value: 'mdi-glass-wine' },
    { text: 'Melancia', value: 'mdi-fruit-watermelon' },
    { text: 'Frutas Cítricas', value: 'mdi-fruit-citrus' },
    { text: 'Cerejas', value: 'mdi-fruit-cherries' },
    { text: 'Uvas', value: 'mdi-fruit-grapes-outline' },
    { text: 'Pera', value: 'mdi-fruit-pear' },
    { text: 'Abacaxi', value: 'mdi-fruit-pineapple' },
    { text: 'Batata Frita', value: 'mdi-french-fries' },
    { text: 'Coxa de Frango', value: 'mdi-food-drumstick-outline' },
    { text: 'Ovo Frito', value: 'mdi-egg-fried' },
    { text: 'Broto', value: 'mdi-sprout-outline' },
    { text: 'Bebida Alcoólica', value: 'mdi-liquor' },
    { text: 'Fogão', value: 'mdi-stove' },
    { text: 'Grelha', value: 'mdi-grill-outline' },
    { text: 'Folha', value: 'mdi-leaf' },
    { text: 'Coquetel', value: 'mdi-glass-cocktail' },
    { text: 'Caneca', value: 'mdi-glass-mug-variant' },
    { text: 'Cerveja', value: 'mdi-beer-outline' },
    { text: 'Secador de Cabelo', value: 'mdi-hair-dryer-outline' },
    { text: 'Aspirador', value: 'mdi-vacuum-outline' },
    { text: 'Spray', value: 'mdi-spray-bottle' },
    { text: 'Talheres Limpos', value: 'mdi-silverware-clean' },
    { text: 'Urso de Pelúcia', value: 'mdi-teddy-bear' },
    { text: 'Televisão', value: 'mdi-television' },
    { text: 'Piscina', value: 'mdi-pool' },
    { text: 'Termômetro de Piscina', value: 'mdi-pool-thermometer' },
    { text: 'Wi-Fi', value: 'mdi-wifi' },
    { text: 'Carro Lateral', value: 'mdi-car-side' },
    { text: 'Carro', value: 'mdi-car' },
    { text: 'Banheira', value: 'mdi-bathtub-outline' },
    { text: 'Chuveiro', value: 'mdi-shower' },
    { text: 'Papel Higiênico', value: 'mdi-paper-roll-outline' },
    { text: 'Cabeça de Chuveiro', value: 'mdi-shower-head' },
    { text: 'Vaso Sanitário', value: 'mdi-toilet' },
    { text: 'Pata', value: 'mdi-paw' },
    { text: 'Berço', value: 'mdi-cradle-outline' },
    { text: 'Mamadeira', value: 'mdi-baby-bottle-outline' },
    { text: 'Carrinho de Bebê', value: 'mdi-baby-carriage' },
    { text: 'Música', value: 'mdi-music' },
    { text: 'Nota Musical', value: 'mdi-music-note' },
    { text: 'Sino', value: 'mdi-bell-outline' },
    { text: 'Violão', value: 'mdi-guitar-acoustic' },
    { text: 'Microfone', value: 'mdi-microphone-variant' },
    { text: 'Cadeira de Rodas', value: 'mdi-wheelchair' },
    { text: 'Caixa de Hospital', value: 'mdi-hospital-box-outline' },
    { text: 'Escudo com Cadeado', value: 'mdi-shield-lock-outline' },
    { text: 'Maleta', value: 'mdi-briefcase-outline' },
    { text: 'Planeta Terra', value: 'mdi-earth' },
    { text: 'Mulher', value: 'mdi-human-female' },
    { text: 'Homem', value: 'mdi-human-male' },
    { text: 'Cachimbo', value: 'mdi-smoking-pipe' },
    { text: 'Proibido Fumar', value: 'mdi-smoking-off' },
    { text: 'Fumar', value: 'mdi-smoking' },
    { text: 'Controle de Videogame', value: 'mdi-gamepad-variant-outline' },
    { text: 'Nadar', value: 'mdi-swim' },
    { text: 'Ferro de Passar', value: 'mdi-iron-outline' },
    { text: 'Cabide', value: 'mdi-hanger' },
    { text: 'Gravata', value: 'mdi-tie' },
    { text: 'Geladeira', value: 'mdi-fridge-outline' },
    { text: 'Gato', value: 'mdi-cat' },
    { text: 'Cachorro', value: 'mdi-dog' },
    { text: 'Cachorro Lateral', value: 'mdi-dog-side' },
    { text: 'Marcador de Mapa', value: 'mdi-map-marker' },
    { text: 'Busca de Mapa', value: 'mdi-map-search-outline' },
    { text: 'Chave', value: 'mdi-key-variant' },
    { text: 'Estante de Livros', value: 'mdi-library-shelves' },
    { text: 'Prateleira', value: 'mdi-bookshelf' },
    { text: 'Livro Aberto', value: 'mdi-book-open-page-variant-outline' },
    { text: 'Sofá', value: 'mdi-sofa-outline' },
    { text: 'Sofá Individual', value: 'mdi-sofa-single-outline' },
    { text: 'Banheira de Hidromassagem', value: 'mdi-hot-tub' },
    { text: 'Liquidificador', value: 'mdi-blender-outline' },
    { text: 'Guarda-Sol de Praia', value: 'mdi-umbrella-beach-outline' },
    { text: 'Banco', value: 'mdi-bench-back' },
    { text: 'Cinema', value: 'mdi-movie-open-outline' },
    { text: 'Contas', value: 'mdi-account-multiple' }
  ];
  return data;
}
