<template>
  <div class="star-rating">
    <span
      v-for="star in 5"
      :key="star"
      class="star"
      :style="getStarStyle(star)"
    >
      ★
    </span>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  methods: {
    getStarStyle(star) {
      const starValue = star - 1;
      const decimal = this.rating - starValue;
      
      let filledPercentage;
      if (decimal >= 1) {
        filledPercentage = 100;
      } else if (decimal > 0) {
        filledPercentage = decimal * 100;
      } else {
        filledPercentage = 0;
      }

      return {
        background: `linear-gradient(90deg, gold ${filledPercentage}%, lightgray ${filledPercentage}%)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent'
      };
    }
  }
};
</script>

<style scoped>
.star-rating {
  display: flex;
}

.star {
  font-size: 2rem;
}
</style>
