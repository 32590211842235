import { http } from '../api';
import qs from 'qs';

const controller = 'scservice';

type ServiceCreate = {
activityId: string,
quantity: number,
operationId: number,
location: string,
priority: number,
client: string,
description: string
}

type ServicePendingResponse = {
  id: string;
  requestTimestamp: string;
  endTimestamp?: string;
  bed: string;
  slaStatus: string;
  department: { id: string; name: string };
  origin?: string;
};

export type ServicePending = {
  id: string;
  requestTimestamp: Date;
  endTimestamp: string | null | undefined;
  bed: string;
  slaStatus: string;
  department: string;
  departmentId: string;
  origin?: string;
};

export interface IRelatoryFilters {
  [key: string]: string | number | Date[] | number[] | string[] | undefined | null;
  attendance?: string | null;
  nm_paciente?: string | null;
  activityId?: string | null;
  requestingAgent?: string;
  requestTimestamp?: Date[] | string[] | null;
  startTimestamp?: string;
  approveTimestamp?: Date[];
  acceptTimestamp?: string;
  endTimestamp?: string;
  cancelTimestamp?: string;
  suspendTimestamp?: string;
  rating?: number | null;
  slaStatus?: string | null;
  slaStatusExceed?: string | null;
  slaStartSuspensionTimestamp?: string;
  slaTotalSuspensionTime?: number;
  createAt?: string;
  bed?: string | null;
  alocatedAgent?: string | null;
}

export type ReportResponse = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Report[];
};

export type Report = {
  startDate: string;
  endDate: string;
  bed?: string;
  slaExceeded?: string;
  department?: string;
  category?: string;
  activity?: string;
  status?: string;
  enabled: boolean;
};

export type Relatory = {
  id: string;
  withDeleted: boolean;
  requestingAgent: string;
  attendanceId: string;
  dt_nascimento: string;
  nm_paciente: string;
  ds_leito: string;
  cd_leito: string;
  unidade_internacao: string;
  bed: string;
  activityId: string;
  categoryId: string;
  requestTimestamp: string;
  startTimestamp: Date;
  approveTimestamp: Date;
  rejectTimestamp: Date;
  acceptTimestamp: Date;
  endTimestamp: string;
  archiveTimestamp: Date;
  cancelTimestamp: Date;
  slaAcceptTimeExceded: string;
  slaStartTimeExceded: string;
  slaEndTimeExceded: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  rating: number | number[];
  companyId: string;
  slaStatus: string;
  badReviews?: boolean;
};
export async function create(service: ServiceCreate): Promise<void> {
  await http.post(`${controller}`, service);
}

export async function getRelatory(payload: IRelatoryFilters): Promise<ReportResponse> {
  let params = qs.stringify(
    {
      ...payload,
      order: 'DESC',
      withDeleted: true,
    },
    { indices: false, skipNulls: true }
  );

  if (payload.slaStatus) {
    params = qs.stringify(
      {
        ...payload,
        order: 'DESC',
        withDeleted: true,
      },
      { indices: false, skipNulls: true }
    );
  }

  const { data } = await http.get<ReportResponse>(`${controller}/getReport?${params}`);

  const items = data.data.map((d) => {
    return {
      ...d,
      slaExceeded: getSlaText(d.slaExceeded ?? ''),
    };
  });

  // data.map((relatory) => {
  //   if (relatory.slaEndTimeExceded === 'exceded') {
  //     relatory.slaEndTimeExceded = 'Excedido';
  //   } else if (relatory.slaEndTimeExceded === 'normal') {
  //     relatory.slaEndTimeExceded = 'Normal';
  //   } else if (relatory.slaEndTimeExceded === 'warning') {
  //     relatory.slaEndTimeExceded = 'Quase Excedido';
  //   }
  // });

  return {
    ...data,
    data: items,
  };
}

function getSlaText(input: string) {
  switch (input) {
    case 'exceded':
      return 'Excedido';
    case 'normal':
      return 'Normal';
    case 'warning':
      return 'Quase Excedido';
    default:
      return '';
  }
}

export async function getServicesPending(): Promise<ServicePending[]> {
  const { data } = await http.get<ServicePendingResponse[]>(`${controller}/pending`);

  return data.map((e) => {
    return {
      id: e.id,
      requestTimestamp: new Date(e.requestTimestamp),
      endTimestamp: e.endTimestamp,
      bed: e.bed,
      slaStatus: e.slaStatus,
      departmentId: e.department.id,
      department: e.department.name,
      origin: e.origin,
    };
  });
}
