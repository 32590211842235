import { Accomodation } from './Accomodation';

export enum RoomType {
  Luxury = 'Luxo',
  SuperLuxury = 'Super Luxo',
  ExecutiveSuite = 'Suíte Executiva',
  GrandSuite = 'Grand Suíte',
  EuropeSuite = 'Suíte Europa',
  MasterApartment = 'Apartamento Master',
}

export type Room = {
  id: string;
  description: string;
  roomNumber: string;
  ramal: string;
  accomodations: Accomodation[];
  type: RoomType;
  floor: string;
  enabled: boolean;
};
