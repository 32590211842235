import { createApp } from 'vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Maska from 'maska';
import VCalendar from 'v-calendar';
import App from './App.vue';
import money from 'v-money3';
import PrimeVue from 'primevue/config';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import './styles/global.css';
import 'v-calendar/dist/style.css';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import VueQrcode from '@chenfengyuan/vue-qrcode';

import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import CountryFlag from 'vue-country-flag-next';
import { createI18n } from 'vue-i18n';
import { messages } from './utils/i18n';

const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: 'pt-BR',
  messages: messages,
});

const app = createApp(App);
fetch('version')
  .then((response) => response.text())
  .then((csvString) => {
    //Split the csv into rows
    console.log(csvString);
    process.env.VUE_APP_VERSION = csvString;

    if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
      Sentry.init({
        app,
        environment: process.env.VUE_APP_ENVIRONMENT,
        release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', process.env.VUE_APP_API_URL as string, /^\//],
          }),
        ],
        debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
        tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
        tracingOptions: {
          trackComponents: false,
        },
        // Vue specific
        logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
        attachProps: true,
        attachStacktrace: true,
      });
    }
  });

app
  .use(router)
  .use(vuetify)
  .use(Maska)
  .use(money)
  .use(VCalendar)
  .use(PrimeVue)
  .use(Vue3ColorPicker)
  .use(i18n)
  .component('VueDatePicker', VueDatePicker)
  .component(VueQrcode.name, VueQrcode)
  .component('country-flag', CountryFlag)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');

