import { http } from "../api";
import { AgentRank } from "../model/AgentRank";

const controller = '/ranking';

export async function get(range?: string[]): Promise<AgentRank[]> {
  const query = range ? `?startDate=${range[0]}&endDate=${range[1]}` : '';
  const { data } = await http.get(`${controller}/agents/${query}`);
  console.log(data);
  return data.agents;
}