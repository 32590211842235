import { Filters, OptionSelectAttendance, PaginateRequest, PaginateResponse } from '@/core/model';
import { Accomodation } from '@/core/model/Accomodation';
import { getPaginate, getAttendanceByName } from '@/core/service/AttendanceService';
import { reactive } from 'vue';

type GuestData = {
  id: string;
  name: string;
  accomodationId: string;
  birthDate: Date;
  floor: string;
  roomNumber: string;
}

const state = reactive<{ attendances?: Accomodation[]; listAttendancesSelect: OptionSelectAttendance[] }>({
  attendances: undefined,
  listAttendancesSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Accomodation> {
  if (!state.attendances) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.attendances;
  if (f?.name) data = data.filter((d) => d.guests?.some(g => g.firstName.toLowerCase().includes(f.name ?? '')));

  const start = (page - 1) * limit;
  const end = start + limit;
  data = state.attendances.slice(start, end);
  return { data, page, recordsTotal: state.attendances.length };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.attendances || forceRefresh) {
      const { data } = await getPaginate();

      state.attendances = data;

      const guests = data.reduce<GuestData[]>(
        (acc, accomodation) => acc.concat(accomodation.guests?.map(guest => ({
          id: guest.id,
          name: `${guest.firstName} ${guest.lastName}`,
          accomodationId: accomodation.id,
          birthDate: guest.birthDate,
          floor: accomodation.room.floor,
          roomNumber: accomodation.room.roomNumber
        })) ?? []), []
      ).filter((d, i, self) => i === self.findIndex((u) => u.id === d.id));

      state.listAttendancesSelect = guests.map((d) => ({
        text: d.name,
        value: d.id,
        attendance: Number(d.accomodationId),
        birthDate: d.birthDate?.toString(),
        unit: d.floor, bed: d.roomNumber
      }));

      state.listAttendancesSelect.sort((a, b) => a.value.localeCompare(b.value));
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Accomodation>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetAttendanceByName(attendanceId : string) {
    const data = await getAttendanceByName(attendanceId);

    const guests = data.reduce<GuestData[]>(
      (acc, accomodation) => acc.concat(accomodation.guests?.map(guest => ({
        id: guest.id,
        name: `${guest.firstName} ${guest.lastName}`,
        accomodationId: accomodation.id,
        birthDate: guest.birthDate,
        floor: accomodation.room.floor,
        roomNumber: accomodation.room.roomNumber
      })) ?? []), []
    ).filter((d, i, self) => i === self.findIndex((u) => u.id === d.id));

    state.listAttendancesSelect = guests.map((d) => ({
      text: d.name,
      value: d.id,
      attendance: Number(d.accomodationId),
      birthDate: d.birthDate?.toString(),
      unit: d.floor,
      bed: d.roomNumber
    }));
  },

  async GetAttendanceByRoom(room : string) {
    await this.GetAll(true);
    console.log("teste",state.listAttendancesSelect)
    console.log("room",room)
    state.listAttendancesSelect = state.listAttendancesSelect.filter((d) => d.bed === room);
  },
};

const getters = {};

export { state, actions, getters };
