import { iconErrorSwalfire } from "@/components/atoms/icons/iconsSwal";
import { ModalTypes } from "@/core/enum/modal";
import { ModalSwal } from "@/core/interfaces/modal";
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

export async function showModal(attributes: ModalSwal):Promise<SweetAlertResult<any>> {

  let config: SweetAlertOptions | any = {
    title: `<p class="swalfire-title-1">${attributes.modalTitle}</p> <p class="swalfire-title-2">${attributes.type_message}</p>`,
    iconHtml: attributes.iconHtml ? attributes.iconHtml : undefined,
    showCloseButton: attributes.showCloseButton ? attributes.showCloseButton : false,
    showCancelButton: attributes.showCancelButton ? attributes.showCancelButton : false,
    focusConfirm: attributes.focusConfirm ? attributes.focusConfirm : true,
    cancelButtonText: attributes.cancelButtonText ? attributes.cancelButtonText : 'Cancel',
    buttonsStyling: attributes.buttonsStyling ? attributes.buttonsStyling : true,
    confirmButtonColor: attributes.confirmButtonColor ? attributes.confirmButtonColor : undefined,
    cancelButtonColor: attributes.cancelButtonColor ? attributes.cancelButtonColor : undefined,
    input: attributes.input ?? attributes.input,
    inputLabel: attributes.inputLabel ? attributes.inputLabel : '',
  }

  switch(attributes.modal_type) {
    case ModalTypes.CONFIRME_MODAL:
      config =  {
        customClass: attributes.customClass ? attributes.customClass : {
          confirmButton: 'swalfire-confirm-button',
        },
        confirmButtonText: attributes.confirmButtonText ? attributes.confirmButtonText :'Finalizar',
        ...config,
      }
      break;
      
    case ModalTypes.YES_MODAL:
      config =  {
        customClass: attributes.customClass ? attributes.customClass : {
          confirmButton: 'swalfire-confirm-button',
          cancelButton: 'swalfire-cancel-button',
          inputLabel: 'swalfire-input-label'
        },
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Voltar',
        buttonsStyling: true,
        inputAttributes: attributes.inputAttributes ? attributes.inputAttributes : {
          style: `border-radius: 8px; border: 1px solid var(--primary-c300); color: var(--primary-c500);`
        },
        inputValidator: (value: string) => {
          if (!value) {
            return 'É necessário inserir a palavra para continuar!';
          }
          if (value.toUpperCase() !== 'SIM') {
            return 'A palavra inserida é diferente da palavra solicitada!';
          }
          return '';
        },
        
        ...config
      }
      break;

    case ModalTypes.SUCESS_MODAL:
      config =  {
        confirmButtonText: 'Finalizar',
        customClass: {
          confirmButton: 'swalfire-confirm-button',
        },
        ...config,
      }
      break;
    
    case ModalTypes.ERROR_MODAL:
      config =  {
        confirmButtonText: 'Finalizar',
        customClass: {
          confirmButton: 'swalfire-confirm-button',
        },
        ...config,
      }
      break;
      
  }
  
  return await Swal.fire(config)

}