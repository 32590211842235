import { Filters, OptionSelectDepartment, PaginateRequest, PaginateResponse } from '@/core/model';
import { Department } from '@/core/model/Department';
import { getPaginate } from '@/core/service/DepartmentService';
import { computed, reactive } from 'vue';

const state = reactive<{ departments?: Department[]; listDepartmentsSelect: OptionSelectDepartment[] }>({
  departments: undefined,
  listDepartmentsSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Department> {
  if (!state.departments) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.departments;
  if (f?.name) data = data.filter((d) => d.name.toLowerCase().includes(f.name ?? ''));

  const recordsTotal = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);
  
  return { data, page, recordsTotal };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.departments || forceRefresh) {
      const { data } = await getPaginate();
      state.departments = data;
      state.departments.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    state.listDepartmentsSelect = state.departments
      .filter((d) => d.enabled)
      .map((d) => (console.log(d),{ text: d.name, value: d.id, checked: false, isVisible: false }));
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters, refresh = false): Promise<PaginateResponse<Department>> {
    await this.GetAll(refresh);
    return applyFilters(paginate, filters);
  },
};

const getters = {
  getListDepartmentsSelect: computed(() => {
    return state.listDepartmentsSelect;
  }),
};

export { state, actions, getters };
