import { http } from "../api";
import { AnalysisRating } from "../model/AnalysisRating";

const controller = '/rating';


export async function get(range?: string[]): Promise<AnalysisRating[]> {
  const query = range ? `?startDate=${range[0]}&endDate=${range[1]}` : '';
  const { data } = await http.get<AnalysisRating[]>(`${controller}${query}`);
  return data;
}

export async function getByDepartment(id: string, range?: string[]): Promise<AnalysisRating[]> {
  const query = range ? `?startDate=${range[0]}&endDate=${range[1]}` : '';
  const { data } = await http.get<AnalysisRating[]>(`${controller}/department/${id}${query}`);
  return data;
}

export async function getByCategory(id: string, range?: string[]): Promise<AnalysisRating[]> {
  const query = range ? `?startDate=${range[0]}&endDate=${range[1]}` : '';
  const { data } = await http.get<AnalysisRating[]>(`${controller}/category/${id}${query}`);
  return data;
}

export async function getByActivity(id: string, range?: string[]): Promise<AnalysisRating[]> {
  const query = range ? `?startDate=${range[0]}&endDate=${range[1]}` : '';
  const { data } = await http.get<AnalysisRating[]>(`${controller}/activity/${id}${query}`);
  return data;
}