import { Filters, OptionSelect, OptionSelectBed, PaginateRequest, PaginateResponse } from '@/core/model';
import { Bed } from '@/core/model/Bed';
import { Room } from '@/core/model/Room';
import { getPaginate, getPatientInfoByBed } from '@/core/service/BedService';
import { reactive } from 'vue';

const state = reactive<{ beds?: Room[]; listBedsSelect: OptionSelectBed[]; listUnitsSelect: OptionSelect[] }>({
  beds: undefined,
  listBedsSelect: [],
  listUnitsSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Room> {
  if (!state.beds) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.beds;
  if (f?.name) data = data.filter((d) => d.roomNumber.toLowerCase().includes(f.name ?? ''));

  const start = (page - 1) * limit;
  const end = start + limit;
  data = state.beds.slice(start, end);
  return { data, page, recordsTotal: state.beds.length };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.beds || forceRefresh) {
      const data = await getPaginate();
      state.beds = data;
      // debugger;
      state.listBedsSelect = data.map((d) => ({
        text: d.roomNumber,
        value: d.id,
        unit: d.floor,
        patientName: null,
        attendance: d.id,
      }));

      state.listUnitsSelect = data
        .map((d) => ({
          text: d.floor,
          value: d.floor,
          bed: d.roomNumber,
        }))
        .filter((d, i, self) => i === self.findIndex((u) => u.value === d.value));

      state.listUnitsSelect.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      state.listBedsSelect.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      state.beds.sort((a, b) => {
        const unitCompare = a.floor.toLowerCase().localeCompare(b.floor.toLowerCase());

        if (unitCompare == 0) {
          return a.roomNumber.toLowerCase().localeCompare(b.roomNumber.toLowerCase());
        }

        return unitCompare;
      });
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Room>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetPatientInfoByBed(dsLeito: string) {
    const data = await getPatientInfoByBed(dsLeito);
    //state.beds = [data];
    state.listBedsSelect = [data].map((d) => ({
      text: d.roomNumber,
      value: d.id,
      unit: d.floor,
      patientName: null,
      attendance: d.id,
    }));
  },
};

const getters = {};

export { state, actions, getters };
