export const messages = {
  'en-US': {
    message: {
      login: {
        hello: 'Welcome!',
      },
    },
  },
  'pt-BR': {
    message: {
      common: {
        success: '',
        error: '',
      },
      login: {
        hello: 'Bem vindo(a)!',
        user: 'Preencha os campos abaixo corretamente para acessar a plataforma',
        version: 'Preencha os campos abaixo corretamente para acessar a plataforma',
      },
      home: {
        hello: 'Bem vindo(a) {name}!',
      },
    },
  },
};
